import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { createFinalizeUpdatePSEndpoint } from '../actions';

import { oauth } from '../utils';

import { BASE_ZINDEX } from '../popup-factory';

class PSTestPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            response: '',
            reference_number: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickTest = this.handleClickTest.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.handleClickTest();
    }

    handleChange(value) {
        this.setState({ reference_number: value });
    }

    handleClickTest() {
        const { endpoint_code, division_id, promo_api_name, tenant_id } = this.props.tenant_endpoint;

        let data;
        switch (endpoint_code) {
            case 'INV':
                data = {
                    admin_test: true,
                    division_id,
                    api_name: promo_api_name,
                    action: 'inventory_level'
                };
                oauth('POST', 'promo-standards', data).then(({ json }) => {
                    this.setState({ response: json ? JSON.stringify(json, undefined, 2) : '', loading: false }, function() {
                        if(json.endpoint) {
                            this.props.onCreateFinalizeUpdatePSEndpoint(json.endpoint.endpoint_id, json.endpoint);
                        }
                    });
                }, function () {
                    this.setState({ response: 'Cannot get inventory level.', loading: false });
                });
                break;
            case 'ODRSTAT':
                data = {
                    admin_test: true,
                    division_id,
                    api_name: promo_api_name,
                    action: 'order_status',
                    tenant_id,
                    query_type: 1
                };
                if (this.state.reference_number != '') {
                    data.reference_number = this.state.reference_number;
                }
                oauth('POST', 'promo-standards', data).then(({ json }) => {
                    this.setState({ response: json ? JSON.stringify(json, undefined, 2) : '', loading: false }, function() {
                        if (json.endpoint) {
                            this.props.onCreateFinalizeUpdatePSEndpoint(json.endpoint.endpoint_id, json.endpoint);
                        }
                    });
                }, function () {
                    this.setState({ response: 'Cannot get order status.', loading: false });
                });
                break;
            case 'OSN':
                data = {
                    admin_test: true,
                    division_id,
                    api_name: promo_api_name,
                    action: 'ship_notification',
                    tenant_id,
                    query_type: 1
                };
                if(this.state.reference_number != '') {
                    data.reference_number = this.state.reference_number;
                }
                oauth('POST', 'promo-standards', data).then(({ json }) => {
                    this.setState({ response: json ? JSON.stringify(json, undefined, 2) : '', loading: false }, function() {
                        if (json.endpoint) {
                            this.props.onCreateFinalizeUpdatePSEndpoint(json.endpoint.endpoint_id, json.endpoint);
                        }
                    });
                }, function () {
                    this.setState({ response: 'Cannot get shipment notification.', loading: false });
                });
                break;
            case 'CRE':
                break;
        }
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    render() {
        const {
            tenant_endpoint,
            onClosePopup,
            index } = this.props;

        return (
            <div id='ps-test-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle'>Promostandards Endpoint Test</h3>
                        <a className='alert button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                    </div>
                </div>
                <div className='row popup-content'>
                    {['ODRSTAT', 'OSN'].indexOf(tenant_endpoint.endpoint_code) !== -1 ?
                        <div className="row">
                            <div className="small-6 columns">
                                <input type="text" placeholder="PO Number" onChange={e => this.handleChange(e.target.value)} />
                            </div>
                            <span><button className="button" onClick={e => { e.preventDefault(); this.handleClickTest(); }}>Test</button></span>
                        </div>
                    : null}
                    {this.state.loading ?
                        this.renderLoading()
                    :
                        <pre>{`${this.state.response}`}</pre>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup());
        },
        onCreateFinalizeUpdatePSEndpoint: (endpoint_id, endpoint) => {
            dispatch(createFinalizeUpdatePSEndpoint(endpoint_id, endpoint));
        }
    };
};

const ConnectedPSTestPopup = connect(mapStateToProps, mapDispatchToProps)(PSTestPopup);
export default ConnectedPSTestPopup;